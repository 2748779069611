html {
  background-color: #3943B4
}

.App {
  text-align: center;
  /* display: flex; */
  /* vertical-align: middle; */
  /* justify-content: center; */
  /* align-content: center; */
  /* align-items: center; */
    position: absolute;
    width: 100%;
    height: 100vh;

    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    
    background-color: #3943B4;
}

.App-link {
  color: #61dafb;
}
