/* Font */
@import url('https://fonts.googleapis.com/css2?family=Leckerli+One&display=swap');

/* Mobile Media Query */
@media (min-width: 320px ) {

    .landing-group {       
        position: relative;
        width: auto;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .landing-img {
        position: relative;
        width: 320px;
        background: url('../../assets/4634837_2464452.jpg') no-repeat;
    }
        
    .white-rect {
        position: absolute;
        width: 49px;
        height: 17px;
        transform: translate(0px, 75px);
        background: #FFFFFF;
    }


    .blue-rect {
        position: absolute;
        width: 110px;
        height: 17px;
        transform: translate(0px, 140px);
        background: #3943B4;
    }

}

/* Tablet Media Query */
@media (min-width: 480px) {
    
    .landing-group {
        width: auto;
        height: auto;
    }

    /* .landing-img {
        width: 470px;
    } */

    .white-rect {
        width: 138px;
        height: 30px;

        transform: translate(0px, 110px);
        background: #FFFFFF;
    }

    .blue-rect {
        position: absolute;
        width: 110px;
        height: 17px;
        transform: translate(0px, 140px);
        background: #3943B4;
        display: none;
    }
}

@media (min-width: 480px) and (min-height: 480px) {
    
    .landing-group {       
        position: relative;
        width: auto;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .landing-img {
        position: relative;
        width: 320px;
        background: url('../../assets/4634837_2464452.jpg') no-repeat;
    }

    .white-rect {
        position: absolute;
        width: 49px;
        height: 17px;
        transform: translate(0px, 75px);
        background: #FFFFFF;
    }

    .blue-rect {
        position: absolute;
        width: 110px;
        height: 17px;
        transform: translate(0px, 140px);
        background: #3943B4;
    }
}

/* Tablet Media Query */
@media (min-width: 600px) {
    
    .landing-group {
        position: relative;
        width: auto;
        height: auto;
    }

/* 
    .landing-img {
        width: 550px;
    } */

    .white-rect {
        width: 140px;
        height: 35px; 

        transform: translate(0px, 125px);
        background: #FFFFFF;
    }

    .blue-rect {
        position: absolute;
        width: 110px;
        height: 17px;
        transform: translate(0px, 140px);
        background: #3943B4;
        display: none;
    }
}

@media (min-width: 600px) and (min-height: 480px) {
    
    .landing-group {       
        position: relative;
        width: auto;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .landing-img {
        position: relative;
        width: 320px;
        background: url('../../assets/4634837_2464452.jpg') no-repeat;
    }

    .white-rect {
        position: absolute;
        width: 49px;
        height: 17px;
        transform: translate(0px, 75px);
        background: #FFFFFF;
    }

    .blue-rect {
        position: absolute;
        width: 110px;
        height: 17px;
        transform: translate(0px, 140px);
        background: #3943B4;
    }
}


/* Small Screen Media Query */
@media (min-width: 768px) {

    .landing-group {
        position: relative;
        width: auto;
        height: auto;
    }

    .landing-img {
        width: 550px;
    }

    .white-rect {
        width: 140px;
        height: 35px; 

        transform: translate(0px, 125px);
        background: #FFFFFF;
    }

    .blue-rect {
        position: absolute;
        width: 110px;
        height: 17px;
        transform: translate(0px, 140px);
        background: #3943B4;
        display: none;
    }
    
}

/* Desktop Media Query */

@media (min-width: 480px) and (min-height: 600px) {

    .landing-group {
        width: auto;
        height: auto;
    }

    .landing-img {
        width: 450px;
    }
    
    .white-rect {
        width: 140px;
        height: 24px;    
        background: #FFFFFF;
        transform: translate(0px, 110px);
    }
    
    .blue-rect {
        display: none;
    }
    
}

/* Desktop Media Query */

@media (min-width: 600px) and (min-height: 600px) {

    .landing-group {
        width: auto;
        height: auto;
    }

    .landing-img {
        width: 450px;
    }
    
    .white-rect {
        width: 140px;
        height: 24px;    
        background: #FFFFFF;
        transform: translate(0px, 110px);
    }
    
    .blue-rect {
        display: none;
    }
    
}



@media (min-width: 768px) and (min-height: 480px) {
    
    .landing-group {       
        position: relative;
        width: auto;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .landing-img {
        position: relative;
        width: 320px;
        background: url('../../assets/4634837_2464452.jpg') no-repeat;
    }

    .white-rect {
        position: absolute;
        width: 49px;
        height: 17px;
        transform: translate(0px, 75px);
        background: #FFFFFF;
    }

    .blue-rect {
        position: absolute;
        width: 110px;
        height: 17px;
        transform: translate(0px, 140px);
        background: #3943B4;
    }
}
/* Desktop Media Query */

@media (min-width: 768px) and (min-height: 600px) {

    .landing-group {
        width: auto;
        height: auto;
    }

    .landing-img {
        width: 450px;
    }
    
    .white-rect {
        width: 140px;
        height: 24px;    
        background: #FFFFFF;
        transform: translate(0px, 110px);
    }
    
    .blue-rect {
        display: none;
    }
    
}


/* Desktop Media Query */
@media (min-width: 768px) and (min-height: 768px) {

    .landing-group {
        width: auto;
        height: auto;
    }

    .landing-img {
        width: 550px;
    }
    
    .white-rect {
        width: 140px;
        height: 35px;    
        background: #FFFFFF;
        transform: translate(0px, 125px);
    }
}


@media (min-width: 768px) and (min-height: 1024px) {

    .landing-group {
        width: auto;
        height: auto;
    }

    .landing-img {
        width: 750px;
    }
    
    .white-rect {
        width: 194px;
        height: 54px;    
        background: #FFFFFF;
        transform: translate(0px, 180px);
    }
    
    .blue-rect {
        display: none;
    }
    
}

/* Desktop Media Query */

@media (min-width: 1024px) and (min-height: 600px) {

    .landing-group {
        width: auto;
        height: auto;
    }

    .landing-img {
        width: 450px;
    }
    
    .white-rect {
        width: 140px;
        height: 24px;    
        background: #FFFFFF;
        transform: translate(0px, 110px);
    }
    
    .blue-rect {
        display: none;
    }
    
}

/* Desktop Media Query */

@media (min-width: 1024px) and (min-height: 768px) {

    .landing-group {
        width: auto;
        height: auto;
    }

    .landing-img {
        width: 550px;
    }
    
    .white-rect {
        width: 140px;
        height: 35px;    
        background: #FFFFFF;
        transform: translate(0px, 125px);
    }
/*     
    .blue-rect {
        display: none;
    }
     */
}

@media (min-width: 1024px) and (min-height: 1024px) {

    .landing-group {
        width: auto;
        height: auto;
    }

    .landing-img {
        width: 750px;
    }
    
    .white-rect {
        width: 194px;
        height: 54px;    
        background: #FFFFFF;
        transform: translate(0px, 180px);
    }
    
    .blue-rect {
        display: none;
    }
    
}
