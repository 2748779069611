ul {
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

li {
    display: flex;
    justify-content: center;
    align-items: center;
}

a {
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;    
}

@media (min-width: 150px) and (min-height: 150px) {
    
    /* 320px */
    a {
        width: 50px;
        display: flex;
        justify-content: center;
        align-items: center;    
    }

    /* 320px */
    .footer-frame {
        position: relative;
        width: auto;
        height: auto;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        
        background: #3943B4;
        transform: translate(0px, -55px);
    }
    
    /* 320px */
    .contact-group {
        position: relative;
        width: auto;
        height: auto;
        padding: 0px 0px 0px 0px;
        
        z-index: 2;
        filter: drop-shadow(0px 4px 4px rgba(138, 72, 72, 0.25));

        display: none;
        
        justify-content: flex-end;
        align-items: center;

        transform: translate(60px, 15px);
    }

    /* 320px */
    .contact-text {
        position: relative;
        width: auto;
        height: auto;
        right: 0px;
        bottom: 0px;

        font-family: 'Leckerli One', cursive;
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 40px;

        color: #FFFFFF;

        rotate: -8deg;
        transform: translate(-85px, -20px);
    }

    /* 320px */
    .contact-vector {
        position: absolute;
        width: 60px;
        height: 70px;

        margin: 0;
        padding: 0;

        display: flex;
        justify-content: center;
        align-items: center;

        scale: 65%;
        rotate: 0deg;
        transform: translate(-260px, -40px);
        
        background: url('../../assets/curvedArrowSmall.svg') no-repeat;
        background-position: center;
    }

    /* 320px */
    .social-group {
        position: relative;
        width: auto;
        height: 35px;
        bottom: 0px;

        padding: 0px 0px 0px 0px;        
        margin: 0px 0px 0px 0px;

        display: flex;
        justify-content: center;
        align-items: center;

        list-style-type: none;

        background: #3943B4;

        transform: translate(0px, 0px);
    }

    /* 320px */
    .email {
        position: relative;
        width: 35px;
        height: 35px;
        display: flex;
        justify-content: center;
        align-items: center;

        background: url('../../assets/mail.png') no-repeat;
        background-size: contain;
        filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.25));
    }

    /* 320px */
    .facebook {
        position: relative;
        width: 13.99px;
        height: 27.99px;
        filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.25));
    }

    /* 320px */
    .tiktok {
        position: relative;
        width: 25.19px;
        height: 27.99px;
        filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.25));
    }

    /* 320px */
    .twitter {
        position: relative;
        width: 34.51px;
        height: 24.99px;
        filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.25));
    }

    /* 320px */
    .instagram {
        position: relative;
        width: 27.99px;
        height: 27.99px;
        filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.25));
    }
}

/* Mobile Media Query */
@media (min-width: 320px) and (min-height: 480px) {

    /* 320px x 533px */
    .contact-group {
        display: flex;
    }

}

/* Tablet Media Query */
@media (min-width: 480px) {
    /* 481px */
    /* a {
        width: 65px;
    } */

    /* 481px */
    .footer-frame {
        transform: translate(0px, -80px);
    }

    /* 481px */
    .contact-group {
        padding: 0px 0px 0px 0px;
        transform: translate(0px, -25px);
    }
    
    /* 481px */
    .contact-vector {
        width: 80px;
        height: 120px;
        background: url('../../assets/curvedArrowMedium.svg') no-repeat;
        background-position: center;
        
        scale: 80%;

        rotate: -20deg;
        transform: translate(-150px, -30px);
    }

    /* 481px */
    .contact-text {
        font-size: 20px;
        transform: translate(-20px, 50px);
    }

    /* 481px */
    .social-group {
        width: auto;
        height: 70px;

        padding: 0px 0px 0px 0px;
        margin: 0px 0px 0px 0px;
        
        /* gap: 10px; */
    }

    /* 481px */
    .email {
        width: 40px;
        height: 40px;
    }
    
    /* 481px */
    .facebook {
        width: 16px;
        height: 32px;
    }

    /* 481px */
    .tiktok {
        width: 28.78px;
        height: 32px;
    }

    /* 481px */
    .twitter {
        width: 39.438px;
        height: 28.57px;
    }

    /* 481px */
    .instagram {
        width: 32px;
        height: 32px;
    }
}

/* Tablet Media Query */
@media (min-width: 600px) {
    /* 600px */
    /* a {
        width: 80px;
        align-self: center;
    } */

    /* 600px */
    .footer-frame {
        transform: translate(0px, -100px);
    }

    /* 600px */
    .contact-group {
        padding: 0px 0px 0px 0px;
        transform: translate(0px, -25px);
    }

    /* 600px */
    .contact-text {
        font-size: 20px;
        transform: translate(-60px, 40px);
    }
    
    /* 600px */
    .contact-vector {
        width: 100px;
        height: 130px;

        rotate: -25deg;
        transform: translate(-200px, -65px);

        scale: 80%;
        background: url('../../assets/curvedArrowMedium.svg') no-repeat;
        background-position: center;
    }

    /* 600px */
    .social-group {
        width: auto;
        height: 75px;

        padding: 0px 0px 0px 0px;
        margin: 0px 0px 0px 0px;
        
        gap: 15px;
    }

    /* 600px */
    .email {
        width: 40px;
        height: 40px;
        scale: 100%;
    }

    /* 600px */
    .facebook {
        width: 16px;
        height: 32px;
        scale: 100%;
    }

    /* 600px */
    .tiktok {
        width: 28.78px;
        height: 32px;
        scale: 100%;
    }

    /* 600px */
    .twitter {
        width: 39.438px;
        height: 28.57px;
        scale: 100%;
    }

    /* 600px */
    .instagram {
        width: 32px;
        height: 32px;
        scale: 100%;
    }
}

/* Small Screen Media Query */
@media (min-width: 768px) {
    a {
        width: 50px;
        align-self: center;
    }

    .footer-frame {
        transform: translate(0px, -100px);
    }

    .contact-group {
        padding: 0px 0px 0px 0px;
        transform: translate(0px, -25px);
    }
    
    .contact-text {
        font-size: 20px;
        transform: translate(-60px, 40px);
    }
    
    .contact-vector {
        width: 100px;
        height: 130px;
        
        rotate: -25deg;
        transform: translate(-200px, -65px);
        
        scale: 80%;
        background: url('../../assets/curvedArrowMedium.svg') no-repeat;
        background-position: center;
    }

    .social-group {
        width: auto;
        height: 75px;

        padding: 0px 0px 0px 0px;
        margin: 0px 0px 0px 0px;
        
        gap: 15px;
    }

    .email {
        width: 40px;
        height: 40px;
        scale: 135%;
    }

    .facebook {
        width: 16px;
        height: 32px;
        scale: 135%;
    }

    .tiktok {
        width: 28.78px;
        height: 32px;
        scale: 135%;
    }

    .twitter {
        width: 39.438px;
        height: 28.57px;
        scale: 135%;
    }

    .instagram {
        width: 32px;
        height: 32px;
        scale: 135%;
    }
}

@media  (min-width: 480px) and (min-height: 480px) {

    /* 320px */
    .footer-frame {
        position: relative;
        width: auto;
        height: auto;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        
        background: #3943B4;
        transform: translate(0px, -55px);
    }
    
    /* 320px */
    .contact-group {
        position: relative;
        width: auto;
        height: auto;
        padding: 0px 0px 0px 0px;
        
        z-index: 2;
        filter: drop-shadow(0px 4px 4px rgba(138, 72, 72, 0.25));

        display: flex;
        
        justify-content: flex-end;
        align-items: center;

        transform: translate(60px, 15px);
    }

    /* 320px */
    .contact-text {
        position: relative;
        width: auto;
        height: auto;
        right: 0px;
        bottom: 0px;

        font-family: 'Leckerli One', cursive;
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 40px;

        color: #FFFFFF;

        rotate: -8deg;
        transform: translate(-85px, -20px);
    }

    /* 320px */
    .contact-vector {
        position: absolute;
        width: 60px;
        height: 70px;

        margin: 0;
        padding: 0;

        display: flex;
        justify-content: center;
        align-items: center;

        scale: 65%;
        rotate: 0deg;
        transform: translate(-260px, -40px);
        
        background: url('../../assets/curvedArrowSmall.svg') no-repeat;
        background-position: center;
    }

    /* 320px */
    .social-group {
        position: relative;
        width: auto;
        height: 35px;
        bottom: 0px;

        padding: 0px 0px 0px 0px;        
        margin: 0px 0px 0px 0px;

        display: flex;
        justify-content: center;
        align-items: center;

        list-style-type: none;

        gap: 0px;

        background: #3943B4;

        transform: translate(0px, 0px);
    }

    /* 320px */
    .email {
        position: relative;
        width: 35px;
        height: 35px;
        display: flex;
        justify-content: center;
        align-items: center;

        background: url('../../assets/mail.png') no-repeat;
        background-size: contain;
        filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.25));
    }

    /* 320px */
    .facebook {
        position: relative;
        width: 13.99px;
        height: 27.99px;
        filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.25));
    }

    /* 320px */
    .tiktok {
        position: relative;
        width: 25.19px;
        height: 27.99px;
        filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.25));
    }

    /* 320px */
    .twitter {
        position: relative;
        width: 34.51px;
        height: 24.99px;
        filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.25));
    }

    /* 320px */
    .instagram {
        position: relative;
        width: 27.99px;
        height: 27.99px;
        filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.25));
    }
}

@media  (min-width: 600px) and (min-height: 480px) {

    /* 320px */
    .footer-frame {
        position: relative;
        width: auto;
        height: auto;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        
        background: #3943B4;
        transform: translate(0px, -55px);
    }
    
    /* 320px */
    .contact-group {
        position: relative;
        width: auto;
        height: auto;
        padding: 0px 0px 0px 0px;
        
        z-index: 2;
        filter: drop-shadow(0px 4px 4px rgba(138, 72, 72, 0.25));

        display: flex;
        
        justify-content: flex-end;
        align-items: center;

        transform: translate(60px, 15px);
    }

    /* 320px */
    .contact-text {
        position: relative;
        width: auto;
        height: auto;
        right: 0px;
        bottom: 0px;

        font-family: 'Leckerli One', cursive;
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 40px;

        color: #FFFFFF;

        rotate: -8deg;
        transform: translate(-85px, -20px);
    }

    /* 320px */
    .contact-vector {
        position: absolute;
        width: 60px;
        height: 70px;

        margin: 0;
        padding: 0;

        display: flex;
        justify-content: center;
        align-items: center;

        scale: 65%;
        rotate: 0deg;
        transform: translate(-260px, -40px);
        
        background: url('../../assets/curvedArrowSmall.svg') no-repeat;
        background-position: center;
    }

    /* 320px */
    .social-group {
        position: relative;
        width: auto;
        height: 35px;
        bottom: 0px;

        padding: 0px 0px 0px 0px;        
        margin: 0px 0px 0px 0px;

        display: flex;
        justify-content: center;
        align-items: center;

        list-style-type: none;

        background: #3943B4;

        transform: translate(0px, 0px);
    }

    /* 320px */
    .email {
        position: relative;
        width: 35px;
        height: 35px;
        display: flex;
        justify-content: center;
        align-items: center;

        background: url('../../assets/mail.png') no-repeat;
        background-size: contain;
        filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.25));
    }

    /* 320px */
    .facebook {
        position: relative;
        width: 13.99px;
        height: 27.99px;
        filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.25));
    }

    /* 320px */
    .tiktok {
        position: relative;
        width: 25.19px;
        height: 27.99px;
        filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.25));
    }

    /* 320px */
    .twitter {
        position: relative;
        width: 34.51px;
        height: 24.99px;
        filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.25));
    }

    /* 320px */
    .instagram {
        position: relative;
        width: 27.99px;
        height: 27.99px;
        filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.25));
    }
}

/* Desktop Media Query */
@media  (min-width: 480px) and (min-height: 600px) {
    

    .footer-frame {
        width: auto;
        transform: translate(0px, -80px);
    }
        

    .contact-group {
        padding: 0px 0px 0px 0px;
        transform: translate(20px, -10px);
        
    }

    .contact-text {
        font-size: 16px;
        transform: translate(-40px, 10px);
    }
    
    .contact-vector {
        width: 100px;
        height: 130px;
        
        rotate: 0deg;
        transform: translate(-230px, -15px);
        
        scale: 60%;
        background: url('../../assets/curvedArrowMedium.svg') no-repeat;
        background-position: center;
    }

    .social-group {
        width: auto;
        height: 75px;
        
        padding: 0px 0px 0px 0px;
        margin: 0px 0px 0px 0px;
        
        gap: 10px;
        z-index: 2;
    }

    .email {
        width: 40px;
        height: 40px;
        scale: 100%;
    }

    .facebook {
        width: 16px;
        height: 32px;
        scale: 100%;
    }

    .tiktok {
        width: 28.78px;
        height: 32px;
        scale: 100%;
    }

    .twitter {
        width: 39.438px;
        height: 28.57px;
        scale: 100%;
    }

    .instagram {
        width: 32px;
        height: 32px;
        scale: 100%;
    }
}

/* Desktop Media Query */
@media  (min-width: 600px) and (min-height: 600px) {
    

    .footer-frame {
        width: auto;
        transform: translate(0px, -80px);
    }
        

    .contact-group {
        padding: 0px 0px 0px 0px;
        transform: translate(20px, -10px);
        
    }

    .contact-text {
        font-size: 16px;
        transform: translate(-40px, 10px);
    }
    
    .contact-vector {
        width: 100px;
        height: 130px;
        
        rotate: 0deg;
        transform: translate(-230px, -15px);
        
        scale: 60%;
        background: url('../../assets/curvedArrowMedium.svg') no-repeat;
        background-position: center;
    }

    .social-group {
        width: auto;
        height: 75px;
        
        padding: 0px 0px 0px 0px;
        margin: 0px 0px 0px 0px;
        
        gap: 10px;
        z-index: 2;
    }

    .email {
        width: 40px;
        height: 40px;
        scale: 100%;
    }

    .facebook {
        width: 16px;
        height: 32px;
        scale: 100%;
    }

    .tiktok {
        width: 28.78px;
        height: 32px;
        scale: 100%;
    }

    .twitter {
        width: 39.438px;
        height: 28.57px;
        scale: 100%;
    }

    .instagram {
        width: 32px;
        height: 32px;
        scale: 100%;
    }
}



/* Desktop Media Query */
@media  (min-width: 768px) and (min-height: 480px) {
    
    /* 320px */
    .social-group {
        position: relative;
        width: auto;
        height: 35px;
        bottom: 0px;

        padding: 0px 0px 0px 0px;        
        margin: 0px 0px 0px 0px;

        display: flex;
        justify-content: center;
        align-items: center;

        list-style-type: none;

        background: #3943B4;

        transform: translate(0px, 0px);
    }

    /* 320px */
    .email {
        position: relative;
        width: 35px;
        height: 35px;
        display: flex;
        justify-content: center;
        align-items: center;

        background: url('../../assets/mail.png') no-repeat;
        background-size: contain;
        filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.25));
        scale: 100%;
    }

    /* 320px */
    .facebook {
        position: relative;
        width: 13.99px;
        height: 27.99px;
        filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.25));
        scale: 100%;
    }

    /* 320px */
    .tiktok {
        position: relative;
        width: 25.19px;
        height: 27.99px;
        filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.25));
        scale: 100%;
    }

    /* 320px */
    .twitter {
        position: relative;
        width: 34.51px;
        height: 24.99px;
        filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.25));
        scale: 100%;
    }

    /* 320px */
    .instagram {
        position: relative;
        width: 27.99px;
        height: 27.99px;
        filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.25));
        scale: 100%;
    }
}

/* Desktop Media Query */
@media  (min-width: 768px) and (min-height: 600px) {
    

    .footer-frame {
        transform: translate(0px, -80px);
    }
        

    .contact-group {
        padding: 0px 0px 0px 0px;
        transform: translate(20px, -10px);
        
    }

    .contact-text {
        font-size: 16px;
        transform: translate(-40px, 10px);
    }
    
    .contact-vector {
        width: 100px;
        height: 130px;
        
        rotate: 0deg;
        transform: translate(-230px, -15px);
        
        scale: 60%;
        background: url('../../assets/curvedArrowMedium.svg') no-repeat;
        background-position: center;
    }

    .social-group {
        width: 720px;
        height: 75px;
        
        padding: 0px 0px 0px 0px;
        margin: 0px 0px 0px 0px;
        
        gap: 10px;
        z-index: 2;
    }

    .email {
        width: 40px;
        height: 40px;
        scale: 100%;
    }

    .facebook {
        width: 16px;
        height: 32px;
        scale: 100%;
    }

    .tiktok {
        width: 28.78px;
        height: 32px;
        scale: 100%;
    }

    .twitter {
        width: 39.438px;
        height: 28.57px;
        scale: 100%;
    }

    .instagram {
        width: 32px;
        height: 32px;
        scale: 100%;
    }
}


/* Desktop Media Query */
@media  (min-width: 768px) and (min-height: 768px) {
    
    a {
        width: 65px;
    }

    /* 1025px */
    .footer-frame {
        transform: translate(0px, -100px);
    }
        
    .contact-group {
        padding: 0px 0px 0px 0px;
        transform: translate(30px, 20px);
    }

    .contact-text {
        font-size: 20px;
        /* scale:135%; */
        transform: translate(-50px, -10px);
    }
    
    .contact-vector {
        width: 100px;
        height: 130px;
        
        rotate: 0deg;
        transform: translate(-240px, -30px);
        
        scale: 80%;
        background: url('../../assets/curvedArrowMedium.svg') no-repeat;
        background-position: center;
        
    }

    /* 1025px */
    .social-group {
        width: 720px;
        height: 75px;
        
        padding: 0px 0px 0px 0px;
        margin: 0px 0px 0px 0px;
        
        gap: 15px;
        z-index: 2;
    }

    /* 1025px */
    .email {
        width: 40px;
        height: 40px;
        scale: 135%;
    }

    .facebook {
        width: 16px;
        height: 32px;
        scale: 135%;
    
    }

    /* 481px */
    .tiktok {
        width: 28.78px;
        height: 32px;
        scale: 135%;
    
    }

    /* 481px */
    .twitter {
        width: 39.438px;
        height: 28.57px;
        scale: 135%;
    
    }

    /* 481px */
    .instagram {
        width: 32px;
        height: 32px;
        scale: 135%;
    
    }
}

/* Full Desktop Media Query */
@media  (min-width: 768px) and (min-height: 1024px) {
    
    /* 1025px */
    .footer-frame {
        transform: translate(0px, -130px);
    }
        

    .contact-group {
        padding: 0px 0px 0px 0px;
        transform: translate(-40px, 15px);
    }

    .contact-text {
        font-size: 20px;
        /* scale:135%; */
        transform: translate(-50px, 10px);
    }
    
    .contact-vector {
        width: 100px;
        height: 100px;
        
        rotate: 0deg;
        transform: translate(-240px, -15px);
        
        scale: 80%;
        background: url('../../assets/curvedArrowMedium.svg') no-repeat;
        background-position: center;
    }

    /* 1025px */
    .social-group {
        width: 720px;
        scale: 100%;
        gap: 50px;
        z-index: 2;
    }

    /* 1025px */
    .email {
        width: 68px;
        height: 68px;
        scale: 100%;
    }
    
    .facebook {
        /* position: relative; */
        width: 25px;
        height: 50px;
        scale: 100%;
    }

    /* 1025px */
    .tiktok {
        /* position: relative; */
        width: 50px;
        height: 50px;
        scale: 100%;
    }

    /* 1025px */
    .twitter {
        /* position: relative; */
        width: 61.33px;
        height: 50px;
        scale: 100%;
    }

    /* 1025px */
    .instagram {
        /* position: relative; */
        width: 50px;
        height: 50px;
        scale: 100%;
    }
}


/* Desktop Media Query */
@media  (min-width: 1024px) and (min-height: 600px) {
    

    .footer-frame {
        transform: translate(0px, -80px);
    }
        

    .contact-group {
        padding: 0px 0px 0px 0px;
        transform: translate(20px, -10px);
        
    }

    .contact-text {
        font-size: 16px;
        transform: translate(-40px, 10px);
    }
    
    .contact-vector {
        width: 100px;
        height: 130px;
        
        rotate: 0deg;
        transform: translate(-230px, -15px);
        
        scale: 60%;
        background: url('../../assets/curvedArrowMedium.svg') no-repeat;
        background-position: center;
    }

    .social-group {
        width: 720px;
        height: 75px;
        
        padding: 0px 0px 0px 0px;
        margin: 0px 0px 0px 0px;
        
        gap: 10px;
        z-index: 2;
    }

    .email {
        width: 40px;
        height: 40px;
        scale: 100%;
    }

    .facebook {
        width: 16px;
        height: 32px;
        scale: 100%;
    }

    .tiktok {
        width: 28.78px;
        height: 32px;
        scale: 100%;
    }

    .twitter {
        width: 39.438px;
        height: 28.57px;
        scale: 100%;
    }

    .instagram {
        width: 32px;
        height: 32px;
        scale: 100%;
    }
}

/* Desktop Media Query */
@media  (min-width: 1024px) and (min-height: 768px) {
    
    /* 1025px */
    .footer-frame {
        transform: translate(0px, -100px);
    }
    
    .contact-group {
        padding: 0px 0px 0px 0px;
        transform: translate(30px, 20px);
    }

    .contact-text {
        font-size: 20px;
        /* scale:135%; */
        transform: translate(-50px, -10px);
    }
    
    .contact-vector {
        width: 100px;
        height: 130px;
        
        rotate: 0deg;
        transform: translate(-240px, -30px);
        
        scale: 80%;
        background: url('../../assets/curvedArrowMedium.svg') no-repeat;
        background-position: center;
        
    }

    /* 1025px */
    .social-group {
        width: 720px;
        height: 75px;
        
        padding: 0px 0px 0px 0px;
        margin: 0px 0px 0px 0px;
        
        gap: 15px;
        z-index: 2;
    }

    /* 1025px */
    .email {
        width: 40px;
        height: 40px;
        scale: 135%;
    }

    .facebook {
        width: 16px;
        height: 32px;
        scale: 135%;
    
    }

    /* 481px */
    .tiktok {
        width: 28.78px;
        height: 32px;
        scale: 135%;
    
    }

    /* 481px */
    .twitter {
        width: 39.438px;
        height: 28.57px;
        scale: 135%;
    
    }

    /* 481px */
    .instagram {
        width: 32px;
        height: 32px;
        scale: 135%;
    
    }
}

/* Full Desktop Media Query */
@media  (min-width: 1024px) and (min-height: 1024px) {
    
    /* 1025px */
    .footer-frame {
        transform: translate(0px, -130px);
    }
        

    .contact-group {
        padding: 0px 0px 0px 0px;
        transform: translate(-40px, 15px);
    }

    .contact-text {
        font-size: 20px;
        /* scale:135%; */
        transform: translate(-50px, 10px);
    }
    
    .contact-vector {
        width: 100px;
        height: 100px;
        
        rotate: 0deg;
        transform: translate(-240px, -15px);
        
        scale: 80%;
        background: url('../../assets/curvedArrowMedium.svg') no-repeat;
        background-position: center;
    }

    /* 1025px */
    .social-group {
        width: 720px;
        scale: 100%;
        gap: 50px;
        z-index: 2;
    }

    /* 1025px */
    .email {
        width: 68px;
        height: 68px;
        scale: 100%;
    }
    
    .facebook {
        /* position: relative; */
        width: 25px;
        height: 50px;
        scale: 100%;
    }

    /* 1025px */
    .tiktok {
        /* position: relative; */
        width: 50px;
        height: 50px;
        scale: 100%;
    }

    /* 1025px */
    .twitter {
        /* position: relative; */
        width: 61.33px;
        height: 50px;
        scale: 100%;
    }

    /* 1025px */
    .instagram {
        /* position: relative; */
        width: 50px;
        height: 50px;
        scale: 100%;
    }
}