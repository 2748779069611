/* Mobile Media Query */
@media (min-width: 320px ) {
    .rainlab-logo {
        position: relative;
        width: 204.27px;
        padding: 25px 0px 0px 0px;

        filter: drop-shadow(5px 5px 4px rgba(0, 0, 0, 0.25));
        z-index: 3;

        transform: translate(0px, 45px);
    }
}

/* Tablet Media Query */
@media (min-width: 480px) {
    .rainlab-logo {
        width: 300px;
        padding: 0px 0px 0px 0px;
        transform: translate(0px, 60px);
    }
}

@media (min-width: 480px) and (min-height: 480px) {
    .rainlab-logo {
        position: relative;
        width: 204.27px;
        padding: 25px 0px 0px 0px;

        filter: drop-shadow(5px 5px 4px rgba(0, 0, 0, 0.25));
        z-index: 3;

        transform: translate(0px, 45px);
    }
}

/* Tablet Media Query */
@media (min-width: 600px) {
    .rainlab-logo {
        width: 300px;
        padding: 0px 0px 0px 0px;
        transform: translate(0px, 80px);
    }
}

@media (min-width: 600px) and (min-height: 480px) {
    .rainlab-logo {
        position: relative;
        width: 204.27px;
        padding: 25px 0px 0px 0px;

        filter: drop-shadow(5px 5px 4px rgba(0, 0, 0, 0.25));
        z-index: 3;

        transform: translate(0px, 45px);
    }
}

/* Large Screen & Desktop Media Query */
@media (min-width: 480px) and (min-height: 600px) {
    .rainlab-logo {
        width: 200px;
        padding: 0px 0px 25px 0px;

        transform: translate(0px, 80px);
    }
}

/* Large Screen & Desktop Media Query */
@media (min-width: 600px) and (min-height: 600px) {
    .rainlab-logo {
        width: 200px;
        padding: 0px 0px 25px 0px;

        transform: translate(0px, 80px);
    }
}

@media (min-width: 768px) and (min-height: 480px) {
    .rainlab-logo {
        position: relative;
        width: 204.27px;
        padding: 25px 0px 0px 0px;

        filter: drop-shadow(5px 5px 4px rgba(0, 0, 0, 0.25));
        z-index: 3;

        transform: translate(0px, 45px);
    }
}

/* Large Screen & Desktop Media Query */
@media (min-width: 768px) and (min-height: 600px) {
    .rainlab-logo {
        width: 200px;
        padding: 0px 0px 25px 0px;

        transform: translate(0px, 80px);
    }
}

/* Large Screen & Desktop Media Query */
@media (min-width: 768px) and (min-height: 768px) {
    .rainlab-logo {
        width: 300px;
        padding: 0px 0px 0px 0px;

        transform: translate(0px, 80px);
    }
}

@media (min-width: 768px) and (min-height: 1024px) {
    
    .rainlab-logo {
        width: 430px;
        padding: 0px 0px 15px 0px;

        transform: translate(0px, 120px);
    }
}

/* Large Screen & Desktop Media Query */
@media (min-width: 1024px) and (min-height: 600px) {
    .rainlab-logo {
        width: 200px;
        padding: 0px 0px 25px 0px;

        transform: translate(0px, 80px);
    }
}

/* Large Screen & Desktop Media Query */
@media (min-width: 1024px) and (min-height: 768px) {
    .rainlab-logo {
        width: 300px;
        padding: 0px 0px 0px 0px;

        transform: translate(0px, 80px);
    }
}

@media (min-width: 1024px) and (min-height: 1024px) {
    
    .rainlab-logo {
        width: 430px;
        padding: 0px 0px 15px 0px;

        transform: translate(0px, 120px);
    }
}